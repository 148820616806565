.btn{
	display: inline-block;
	min-width: 15rem;
	padding: 10px 15px;
	font-family: $standard_family;
	text-align: center;
	text-transform: uppercase;
	background: #eee;
	border: 1px solid transparent;
	box-shadow: none;
	transition: .2s all linear;
	cursor:pointer;
	
	&:hover {
		color: $sec;
		background: $main;
	}
	&-primary{
		color: #fff;
		background: $main;
		&:hover{
			color: $main;
			background: $sec;
		}
	}
	&-secondary{
		color: $main;
		background: $sec;
		&:hover{
			color: #fff;
			background: $main;
		}
	}
	&-empty{
		padding: 0;
		color: $main;
		text-transform: uppercase;
		background: transparent;
		&:hover{
			color: $sec;
			background: transparent;
		}
	}
}