.container-left{
  padding-left:20vmin;
}
.container-right{
  padding-right:20vmin;
}

@media only screen and (max-width: 1600px){
  .container-left{
    padding-left: 15vmin;
  }
  .container-right{
    padding-right: 15vmin;
  }
}

@media only screen and (max-width: 1440px){
  .container-left{
    padding-left: 10vmin;
  }
  .container-right{
    padding-right: 10vmin;
  }
}
@media only screen and (max-width: 1200px){
  .container-left{
    padding-left: 5vmin;
  }
  .container-right{
    padding-right: 5vmin;
  }
}