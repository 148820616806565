.form{
	position: relative;
	width: 100%;
	&-contact{
		padding: 0;
	}
	& [class*="col-"]{
		padding: 0 15px;
	}
	fieldset{
		border:none;
		padding:0 15px;
		margin:0;
		.form-checkbox{
			padding-right: 15px;
		}
	}
	&-title{
		display: block;
		font-size: 2rem;
		margin: 0 0 1.5rem;
	}
	&-group{
		margin:0 0 2rem;
		>label{
			display: block;
			color: #fff;
			padding: 0 15px 5px;
		}
	}
	&-help{
		display: block;
		margin:1rem 0;
		font-size:1.3rem;
		color: #fff;
	}
	&-input{
		label{
			display: block;
			font-size:1.4rem;
			color: #fff;
			margin: 0 0 5px;
		}
		&__disabled{
			input{
				background-color: $gray;
			}
		}
	}
	&-control{
		width:100%;
		padding: 1.3rem 1.5rem;
		margin:0;
		color: #fff;
		font-weight: 300;
		background: transparent;
		box-shadow:none;
		border: 1px solid #fff;
		&::placeholder{
			color: #fff;
			font-weight: 300;
		}
		&:focus{
			border-color: $main;
		}
	}
	&-date{
		padding-right: 35px;
		background: url(../img/date.png) no-repeat;
		background-position: 96% 18px;
	}
	&-checkbox{
		position: relative;
		display: inline-block;
		input{
			display: block;
			position: absolute;
			width: 16px;
			top: 5px;
			opacity: 0;
			&:checked ~ label{
				&:before{
					content: "\f14a";
					font-family: 'Font Awesome 5 Free';
					font-weight: 900;
					color: #fff;
				}
			}	
		}
		label{
			display: block;
			position: relative;
			font-size: 1.3rem;
			cursor: pointer;
			&:before{
				content: "\f0c8";
				font-family: 'Font Awesome 5 Free';
				font-weight: 400;
				color: #fff;
				margin-right: 15px;
			}
		}
	}
	&-radio{
		position: relative;
		input{
			display: block;
			position: absolute;
			width: 16px;
			top: 5px;
			opacity: 0;
			&:checked ~ label{
				&:before{
					content: "\f058";
					font-family: 'Font Awesome 5 Free';
					font-weight: 900;
					color: $main;
				}
			}	
		}
		label{
			display: block;
			position: relative;
			cursor: pointer;
			&:before{
				content: "\f111";
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				color: #fff;
				margin-right: 2vmin;
			}
		}
	}
	&-select{
		width:100%;
		padding: 1.3rem 2.5rem;
		margin:0;
		color: #fff;
		background: transparent;
		box-shadow:none;
		border: 1px solid #fff;
		border-radius: 25px;
		appearance: none;
		option{
			background: $sec;
		}
		&:focus{
			border-color: $sec;
		}
		&.form-drop{
			padding-right: 35px;
			appearance: none;
			background: url(../img/dropdown.png) no-repeat;
			background-position: 96% 18px;
			-webkit-appearance: none;
			outline:none;
		}
	}
	.btn{
		padding:0;
		margin:3rem 0;
		text-align: left;
	}
}