.page-header{
	position: fixed;
	top:0;
	left:0;
	width:100%;
	z-index: 10;
	transition: .2s all linear;;
	&--move{
		background: rgba(#171717, 0.9);
	}
}
.navbutton {
    width: 50px;
    height: 40px;
    display: flex;
    position: relative;
    right: 10px;
    top: 0px;
		margin: 5px 10px;
    line-height: 5px;
    align-items: center;
    justify-content: center;
		flex-wrap: wrap;
		background:transparent;
		border: none;
		&-item {
			display: inline-block;
			width: 25px;
			height: 2px;
			margin: 3px 0;
			background: #fff;
			transition: 0.2s all linear;
	}
	&.is-active {
		.navbutton-item {
			opacity: 0;
		}
		.navbutton-item:last-of-type,
		.navbutton-item:first-of-type {
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 17px;
				opacity: 1;
		}
		.navbutton-item:first-of-type{
			transform:rotate(45deg)
		}
		.navbutton-item:last-of-type{
			transform:rotate(-45deg)
		}
	}
}
.menu{
	display: none;
	&-list{
		width:100%;
		text-align: center;
		padding: 0;
		margin: 0;
	}
	&-item{
		display: block;
		font-size: 1.8rem;
		margin: 0 1vw;
		&__link{
			display: inline-block;
			font-weight: 400;
			font-family: $headings_family;
			text-transform: uppercase;
			padding: 25px 10px;
			color: #fff;
			&:after{
				content:'';
				display: block;
				width: 25px;
				height: 2px;
				background: #fff;
				margin: 0 auto;
				opacity: 0;
				transition: .2s all linear;
			}
			&:hover{
				&:after{
					opacity:1;
				}
			}
		}
	}
	&.is-active{
		display:block;
	}
}