.fa-phone::before{
	content:'\f095';
}
.fa-envelope::before{
	content:'\f0e0';
}
.fa-facebook-square::before{
	content:'\f082';
}
.fa-instagram::before{
	content:'\f16d';
}
.fa-arrow-left::before{
	content:'\f060';
}
.fa-arrow-right::before{
	content:'\f061';
}