.section{position: relative;overflow: hidden;}


.section-1{
	min-height: 100vh;
	// padding: 25vmin 0 0;
	background: #444;
	background-size: cover;
	background-position: left;
}

.zoom{
	&-slide{
		min-height:100vh;
		overflow: hidden;
		&.swiper-slide-active{
			img{
				animation-name: uk-scale-12;
				animation-duration: 10s;
				animation-direction: reverse;
			}
		}
	}
	&-item{
		img{
			width: 100%;
			height: 100vh;
			object-fit: cover;
			object-position: left;
		}
	}
}
.animation-top-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.animation-top-center {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.animation-top-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
@keyframes uk-scale-12 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.welcome{
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	height:100%;
	align-content:center;
	justify-content: center;
	text-align: center;
	flex-wrap:wrap;
	color: #fff;
	z-index: 9;
	&-title{
		width: 100%;
		font-size: 15vmin;
		text-transform: uppercase;
		b{
			-webkit-text-stroke: 2px #fff;
			color: transparent;
		}
		img{
			display: block;
			width: 60%;
			margin: 0 auto;
		}
	}
	&-subtitle{
		display: block;
		font-size: 2.5vmin;
		text-transform: uppercase;
		font-weight: 300;
		letter-spacing: 3px;
	}
	&-scroll{
		display: inline-block;
		// width:100%;
		margin: 15vmin 0 0;
		cursor: pointer;
		&__icon{
			display: inline-block;
			max-width: 30px;
			transition: .2s all linear;
		}
		&__text{
			display: block;
			margin: 5px 0;
			text-transform: uppercase;
			font-weight: 300;
			letter-spacing: 2px;
		}
		&:hover{
			.welcome-scroll__icon{
				transform: translateY(-5px)
			}
		}
	}
}
.fast-contact{
	position: absolute;
	width: 100%;
	bottom: 2vh;
	z-index: 9;
}
.contact-phone{
	display: flex;
	align-items: center;
	&__icon{
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 38px;
		color: $main;
		text-align: center;
		border: 1px solid #fff;
		border-radius: 100%;
	}
	&__text{
		font-weight: 300;
		font-size: 1.8rem;
		color: #fff;
		padding: 0 1rem;
	}
}
.contact-email{
	display: flex;
	align-items: center;
	&__icon{
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 38px;
		color: $main;
		text-align: center;
		border: 1px solid #fff;
		border-radius: 100%;
	}
	&__text{
		font-weight: 300;
		font-size: 1.8rem;
		color: #fff;
		padding: 0 1rem;
	}
}
.contact-social{
	display: inline-flex;
	align-items: center;
	color:#fff;
	&__text{
		display: inline-flex;
		align-items: center;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 2px;
		&::after{
			content:'';
			display: inline-block;
			width: 3vw;
			height: 1px;
			margin: 0 10px;
			background: #FFF;
		}
	}
	&__link{
		display: inline-block;;
		color: $main;
		font-size: 2rem;
		margin: 0 5px;
		transition: .2s all linear;
		&:hover{
			transform: translateY(-5px)
		}
	}
}

.ofert-home{
	display: flex;
	flex-wrap: wrap;
	align-items: center;;
	background: #171717;
	overflow: hidden;
}
.ofert-item{
	position: relative;
	width: 100%;
	min-height:50vh;
	background: #333;
	overflow: hidden;
	transition: .3s all linear;
	&__content{
		min-height: 50vh;
    display: flex;
    align-content: space-around;
    justify-content: center;
		flex-wrap: wrap;
		&::after{
			content:'';
			display:block;
			position: absolute;
			width:100%;
			height: 100%;
			background:transparent;
			opacity: 0.5;
			z-index: 1;
			transition: .2s all linear;
		}
		&.ofert-paintball:hover{
			&::after{
				background:#C11111
			}
		}
		&.ofert-mobilna-balia:hover{
			&::after{
				background:#DA712C
			}
		}
		&.ofert-splywy-kajakowe:hover{
			&::after{
				background:#275AA3
			}
		}
		&.ofert-paintball-dla-dzieci:hover{
			&::after{
				background:#2E5D40
			}
		}
		&.ofert-fotobudka-lustro:hover{
			&::after{
				background:#4B3368
			}
		}
		
	}
	&__bc{
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit:cover;
		filter: grayscale(100%);
		z-index: 0;
		transition: .2s all linear;
	}
	&__logo{
		position: relative;
		width: 100px;
		margin: 0 auto;
		z-index: 2;
	}
	&__title{
		position: relative;
		width:100%;
		display: block;
		text-align: center;
		text-transform: uppercase;
		font-size: 3.5vmin;
		color:#fff;
		z-index: 2;
		b{
			-webkit-text-stroke: 1px #fff;
			color: transparent;
		}
	}
	.btn-ofert{
		position: relative;
		z-index: 2;
	}
	&:hover{
		box-shadow: 0 0 35px #171717;
		z-index:2;
		.ofert-item__bc{
			filter:grayscale(0)
		}
	}
}

.about-us{
	padding: 20vmin 0;
	min-height: 80vh;	
	background: #171717 url(../img/about-us-bc.png) no-repeat fixed;
	background-size: cover;
	background-position: top;
	.about-red-text{
		display: inline;
		font-family: $headings_family;
		font-size: 3rem;
		font-weight: 600;
		line-height: 2.5;
		color: #fff;
		box-shadow: 0 0 0 10px $main;
		background: $main;
	}
	.contact-phone{
		margin: 15px 0;
	}
	.about-text{
		height:100%;
		padding: 5vmin;
		background: #fff;
	}
	.about-btn{
		margin: 3rem 0;
	}
	.about-slide{
		img{
			width: 100%;
		}
	}
}
.about-nav-btns{
	position: absolute;
	bottom:2rem;
	right: 2rem;
	.btn-nav{
		display: inline-block;
		width:35px;
		height:35px;
		line-height: 35px;
		text-align: center;
		color: $main;
		border: 1px solid $main;
		border-radius: 100%;
		cursor: pointer;
	}
}

.about-interesing{
	display: flex;
	align-items: center;
	width: 100%;
	margin: 10vh 0;
	justify-content: space-between;
	flex-wrap: wrap;
	&__text{
		margin: 2rem 0;
		font-family: $headings_family;
		font-size:5vmin;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 1;
		color: #fff;
		b{
			-webkit-text-stroke: 1px #fff;
			color: transparent;
		}
	}
}
.about-item{
	margin: 0 0 3rem;
	color: #fff;
	text-align: center;
	&__icon{
		display: block;
		width: 8rem;
		height: 8rem;
		padding: 1rem;
		margin: 3rem auto;
		line-height: 7.5rem;
		text-align: center;
		border: 1px solid $main;
		border-radius: 100%;
		img{
			width: 25px;
			height: auto;
		}
	}
	&__text{
		font-size: 1.8rem;
		line-height: 1.8;
	}
}

.news-home{
	padding: 5vmin 0 0;
	margin: 0 0 -5vmin
}
.home-news-image{
	position: relative;
	z-index:2;
}
.home-news-box{
	padding: 5vmin 0;
}
.news-content{
	.news-date{
		font-family: $headings_family;
		font-size: 1.6rem;
		color: #919191;
	}
	.news-title{
		font-family: $headings_family;
		font-size: 2rem;
		margin: 0 0 3rem;
	}
	.news-btn{
		margin: 3rem 0;
		.btn{
			padding:0;
			padding-right: 1.5rem;
			text-align: left;
		}
	}
}

.contact{
	padding: 15vmin 0 5vmin;
	background: url(../img/contact-bc.jpg) no-repeat fixed;
	background-size: cover;
	background-position: top;
	color: #fff;
	&-content{
		padding-right: 10vw;
	}
	.contact-phone, .contact-email{
		margin: 4rem 0;
	}
}