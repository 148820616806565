$light_gray:#d6d1d1;
$gray:#c5b6b6;
$dark_gray:#a09494;
$darker_gray:#817878;

$light_black: rgb(56, 56, 56);
$medium_black: rgb(43, 43, 43);
$dark_black: rgb(32, 32, 32);

//* THEME COLORS
$main: #E21012;
$sec: #000;
$text: #696969;
$text_color: #000;

//* font families
$standard_family: 'Roboto Condensed', sans-serif;
$headings_family: 'Montserrat', sans-serif;

//* font weights
$font_bold: 700;
$font_regular: 500;
$font_light: 300;

//* font sizes
$standard_font_size: 1.6rem;  // <p><li><a>  font-size

$min_width: 320px;
$max_width: 1600px;

$h1_min_font: 24px;
$h1_max_font: 50px;

$h2_min_font: 23px;
$h2_max_font: 40px;

$h3_min_font: 22px;
$h3_max_font: 35px;

$h4_min_font: 21px;
$h4_max_font: 30px;

$h5_min_font: 20px;
$h5_max_font: 25px;

$h6_min_font: 18px;
$h6_max_font: 20px;
