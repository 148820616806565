.page-footer{
	border-top: 1px solid #f2f2f2;
	padding: 1.5rem 0;
	b, strong{
		color: $main;
	}
}
.eu-baner{
	padding: 1rem 0;
}
.ofert-list-bottom{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	.ofert-list{
		&__item{
			display: inline-block;
			margin: 1rem;
			transition: .2s all linear;
			&:hover{
				transform: translateY(-5px)
			}
		}
	}
}