@media (min-width: 992px){
	.navbutton{
		display: none;
	}
	.menu{
		display: block;
		&-list{
			display: inline-block;
		}
		&-item{
			display: inline-block;
		}
	}
	.ofert-item{
	width: 20%;
	min-height:100vh;
	transform: skew(-15deg);
	background: #333;
	overflow: hidden;
	transition: .3s all linear;
	&__content{
		width: 190%;
		min-height: 100vh;
		margin-left: -45%;
    display: flex;
    align-content: space-around;
    justify-content: center;
		flex-wrap: wrap;
		transform: skew(15deg);
	}
	&__bc{
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit:cover;
		filter: grayscale(100%);
		z-index: -1;
		transition: .2s all linear;
	}
	&__logo{
		margin-left: 50%;
	}
	&__title{
		display: block;
		width: 100%;
	}
	.btn-ofert{
		margin-left:-20%;
	}
	&:hover{
		box-shadow: 0 0 35px #171717;
		z-index:2;
		.ofert-item__bc{
			filter:grayscale(0)
		}
	}
}
	// .submenu{
	// 	border-right: 1px solid $main;
	// }
	
	.why{
		&-body{
			position: absolute;
			top:0;
			left: 0;
			background: #fff;
			box-shadow: 0 0 2px 2px #eee;
			transform: scale(0);
		}
		&-item{
			&:hover{
				.why-body{
					animation: bounce .5s .2s forwards;
				}
			}
		}
		
	}
	
}