i[class*="h-fa-"]{font-style:normal}
[class*="h-fa-"]:before, [class*="h-fa-"]:after{font-family:'FontAwesome';}
.h-clearfix::after {display:block;clear:both;content:""}
.h-white{color:#fff}

.h-ai-c{display:flex;align-items:center}
.h-ai-s{display:flex;align-items:flex-start}
.h-ai-e{display:flex;align-items:flex-end}
.h-as-s{align-self:center}
.h-as-s{align-self:center}
.h-as-s{align-self:center}

.h-ta-c{text-align:center}
.h-ta-r{text-align:right}
.h-ta-l{text-align:left}
.h-ta-j{text-align:justify}
.h-tt-u{text-transform:uppercase}
.h-tt-l{text-transform:lowercase}

.h-fs-i{font-style:italic}
.h-fw-b{font-weight:$font_bold}
.h-fw-r{font-weight:$font_regular}
.h-fw-l{font-weight:$font_light}
.h-flex{display:flex}
.h-flex-w{flex-wrap:wrap}
.h-f-r{float:right}
.h-f-l{float:left}
.h-fd-rr{flex-direction:row-reverse}
.h-fw{width:100%}
.h-fh{height:100%}

.h-m-0{margin:0}
.h-mt-0{margin-top:0}
.h-mb-0{margin-bottom:0}
.h-mtb-0{margin-top:0;margin-bottom:0}
.h-ml-0{margin-left:0}
.h-mr-0{margin-right:0}
.h-mlr-0{margin-left:0;margin-right:0}
.h-ml-a{margin-left:auto}
.h-mr-a{margin-right:auto}
.h-mt-a{margin-top:auto}
.h-mlr-a{margin-left:auto;margin-right:auto}
.h-mr-2{margin-right:2vmin}

.h-mt-1{margin-top:1vmin}
.h-mt-2{margin-top:2vmin}
.h-mt-3{margin-top:3vmin}
.h-mt-4{margin-top:4vmin}
.h-mt-5{margin-top:5vmin}


.h-p-0{padding:0}
.h-pt-0{padding-top:0}
.h-pb-0{padding-bottom:0}
.h-ptb{padding-top:0;padding-bottom:0}
.h-pl-0{padding-left:0}
.h-pr-0{padding-right:0}
.h-plr-0{padding-left:0;padding-right:0}

.h-jc-c{display:flex;justify-content:center}
.h-ja-c{display:flex;justify-content:center;align-items:center}
.h-jc-s{display:flex;justify-content:flex-start}
.h-jc-e{display:flex;justify-content:flex-end}
.h-jc-sa{display:flex;justify-content:space-around}
.h-jc-sb{display:flex;justify-content:space-between}
.h-jc-se{display:flex;justify-content:space-evenly} 
.h-ji-c{display:flex;align-content:center}

.h-z-1{z-index:1}
.h-z-2{z-index:2}
.h-z-3{z-index:3}

.h-of-h{overflow:hidden}

.h-bs-1{box-shadow:0 3px 6px #00000002, 0 3px 6px #00000004}
.h-bs-2{box-shadow:0 3px 6px #00000005, 0 3px 6px #00000009}