@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap&subset=latin-ext');
body {
	font-family: $standard_family;
	font-size: $standard_font_size;
  color: $text_color;
}

h1{@include fluid-type($h1_min_font, $h1_max_font)}
h2{@include fluid-type($h2_min_font, $h2_max_font)}
h3{@include fluid-type($h3_min_font, $h3_max_font)}
h4{@include fluid-type($h4_min_font, $h4_max_font)}
h5{@include fluid-type($h5_min_font, $h5_max_font)}
h6{@include fluid-type($h6_min_font, $h6_max_font)}
h1,h2,h3,h4,h5,h6{font-family:$headings_family;}

h1,h2,h3,h4,h5,h6{margin-top:0;margin-bottom:.5rem}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{margin-bottom:.5rem;line-height:1.2}

p{margin: 0;}

/*font Awesome*/
@font-face{
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg");
}
@font-face{
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg");
}
@font-face{
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg");
}
.fab, .far, .fas{
  font-style: normal;
}
.fab:before,.fab:after{
  font-family: 'Font Awesome 5 Brands';
}
.far:before,.far:after{
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}
.fas:before,.fas:after{
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.title{
	font-size: 5vmin;
	margin: 0 0 3rem;
	text-transform: uppercase;
}
.desc{
  font-family: $headings_family;
  line-height: 1.8;
  h3{
    display: block;
    padding: 10px 0;
    background-color: #f3f3f3;
    margin: 15px 0;
  }
}