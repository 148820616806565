.subpage{
	&-head{
		color:#fff;
		padding: 15vmin 0 10vmin;
		background-image: url(../img/subpage-head.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	&-body{
		min-height: 80vh;
		padding: 5vmin 0;
	}
}
.news-item{
	margin: 0 0 3rem;
}
.news-image{
	width: 100%;
	height: auto;
}
// .submenu{
// 	margin: 0 0 5vh;
// 	&-title{
// 		font-size: 2rem;
// 		font-weight: 600;
// 		text-align: center;
// 		margin: 0 0 3vmin;
// 	}
// 	ul{
// 		margin:0;
// 		padding:0;
// 		li{
// 			display: block;
// 			position: relative;
// 			&.menuactive{
// 				>a{
// 					color: #fff;
// 					background: $main;
// 				}
// 			}
// 			a{
// 				display: block;
// 				padding: 20px;
// 				margin-bottom: 10px;
// 				&:hover{
// 					color: #fff;
// 					background: $main;
// 				}
// 			}
// 		}
// 	}
// 	.menudepth0{
// 		.dropdown-icon{
// 			position: absolute;
// 			right:0;
// 			top:0;
// 			&:before{
// 				content: '\f078';
// 				font-family: 'Font Awesome 5 Free';
// 				font-weight: 900;
// 				position: absolute;
// 				right: 0;
// 				top: 0;
// 				width: 40px;
// 				height: 45px;
// 				line-height: 60px;
// 				text-align: center;
// 				cursor: pointer;
// 			}
// 		}
// 		ul{
// 			max-height: 0;
// 			overflow: hidden;
// 			transition: .2s all linear;
// 			&.is-active{
// 				max-height: 50rem;
// 			}
// 			li{
// 				padding: 0 0 0 35px;
// 				&.menuactive{
// 					a{
// 						color: #fff;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

.submenu{
	margin: 0 0 5vh;
	&-title{
		font-size: 2rem;
		font-weight: 600;
		text-align: center;
		margin: 0 0 3vmin;
	}
	ul{
		margin:0;
		padding:0;
		text-align: center;
		li{
			display: inline-block;
			position: relative;
			margin: 0 2px;
			&.menuactive{
				>a{
					color: #fff;
					background: $main;
				}
			}
			a{
				display: block;
				padding: 20px;
				margin-bottom: 10px;
				background: #f2f2f2;
				&:hover{
					color: #fff;
					background: $main;
				}
			}
		}
	}
}
.gallery{
	&-item{
		display: block;
		margin: 0 0 30px;
		&__img{
			display: block;
			width: 100%;
			height: auto;
			transition: .2s all linear;
			&:hover{
				box-shadow: 0 0 5px 2px #3c3c3c;
			}
		}
	}
}
.why{
	&-header{
		text-align: center;
	}
	&-image{
		display: block;
		width: 100%;
		height: auto;
	}
	&-title{
		display: flex;
		margin: 0;
		align-items: center;
		justify-content: center;
		font-size: 2.4rem;
		font-weight: 400;
		padding: 15px 0;
		height: 90px;
		color: white;
		background-color: #E21012;
	}
	&-body{
		display: flex;
		width: 100%;
		height: 100%;
		padding: 15px;
		align-items: center;
		text-align: center;
		justify-content: center;
	}
	&-item{
		position: relative;
		min-height: 300px;
		margin: 0 0 30px
	}
	
}
@keyframes bounce {
	0%{transform:scale(0)}
	60%{transform:scale(1.1)}
	80%{transform:scale(1.05)}
	100%{transform:scale(1)}
}
.who{
	&-item{
		position: relative;
		margin: 0 0 30px;
		border: 1px solid #c5b6b6;
	}
	&-image{
		width: 100%;
		max-height: 300px;
		object-fit: cover;
		object-position: center;;
	}
	&-body{
		padding: 10px 0px;
	}
	&-title{
		text-align: center;
		font-size: 2.4rem;
		background-color: #f3f3f3;
		margin: 0 0 15px;
		padding: 10px;
	}
}

.scenario{
	&-item{
		padding: 3vmin 0;
		margin: 0 0 30px;
		border-bottom: 1px solid #c5b6b6;
	}
	&-body{
		padding: 3rem 15px;
	}
	&-title{
		font-size: 2.4rem;
		margin: 0 0 15px;
		padding: 10px;
		background-color: #f3f3f3;
	}
	&-image{
		img{
			width: 100%;
			height: 300px;
			object-fit: cover;
			object-position: center;
		}
	}
}

.pricelist{
	&-item{
		margin: 0 0 30px;
		border: 1px solid #c5b6b6;
		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
	&-body{
		padding: 10px 0px;
		a{
			font-weight: 600;
			color: $main;
		}
	}
	&-title{
		font-size: 2.4rem;
		text-align: center;
		margin: 0 0 15px;
		padding: 0 0 5px;
		border-bottom: 1px solid $main;
	}
	&-price{
		font-size: 4rem;
		font-weight: 600;
		color: #fff;
		text-align: center;
		background: $main;
	}
}

.mob-pricelist{
	&-item{
		margin: 0 0 30px;
		border: 1px solid #c5b6b6;
	}
	&-body{
		// display: flex;
		// justify-content: center;
	}
	&-text{
		padding: 15px 10px;
		text-align: center;
		line-height: 2;
		border-bottom: 1px solid #c5b6b6;
		&:last-of-type{
			border-bottom:none;
		}
		a{
			font-weight: 600;
			color: $main;
		}
	}
	&-price{
		padding:15px;
		font-size: 2rem;
		color: #fff;
		text-align: center;
		background:$main;
	}
	&-title{
		display: block;
		width: 100%;
		font-size: 1.8rem;
		text-align: center;
		padding: 10px 15px;
		border-bottom: 1px solid $main;
	}
}

.where{
	&-list{
		margin: 0;
		padding: 1.5rem 0;
		text-align: center;
	}
	&-item{
		display: inline-block;
		margin: 2px;
	}
	&-link{
		display: block;
		font-size: 2rem;
		font-weight: 600;
		color: #fff;
    padding: 15px 25px;
    text-transform: uppercase;
		background: $main;
		&:hover{
			color: #333;
			background: #f2f2f2;
		}
	}
}